import React from "react"
import { graphql } from "gatsby"

import { useCore } from "../hooks/useCore"
import { useShopify } from "../hooks/useShopify"
// import { GET_COLLECTION } from "../graphql/queries/collection"

// import { Collection as Template } from "../components/Collection/Collection"

export const query = graphql`
  query($handle: String!) {
    collection: shopifyCollection(handle: { eq: $handle }) {
      ...GatsbyCollectionFragment
    }
    content: allSanityCollection(filter: { shopify: { shopifyHandle: { eq: $handle } } }, sort: { order: DESC, fields: _updatedAt }) {
      edges {
        node {
          shopify {
            id: shopifyId
            handle: shopifyHandle
          }
          metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
        }
      }
    }
    template: sanityTemplateCollection {
      additionalLoadMoreButton
      additionalNextPage
      additionalPreviousPage
      settingShowSubCollections
    }
  }
`

export default ({ data, ...props }): JSX.Element => {
  const {
    graphql: {
      queries: { GET_COLLECTION },
    },
  } = useCore()
  const { useQuery } = useShopify()
  const {
    collection: { handle },
  } = data

  const { data: live, loading, error } =
    typeof window !== `undefined`
      ? useQuery(GET_COLLECTION, {
          fetchPolicy: `cache-and-network`,
          variables: { handle },
        })
      : { data: false, loading: false, error: false }

  if (error) console.error(error)

  return null //<Template {...props} {...data} live={live} loading={loading} />
}
